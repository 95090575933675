import React, { useState } from 'react';
import Layout from '../components/Layout';
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import { SEO } from '../components/seo';

import { useForm } from '../hooks'

const rowPadding = {
  padding: "100px 0"
}

export default function Feedback() {

    // const firstnameInput = useRef(null);
    const [response, setResponse] = useState('');
    // const [token, setToken] = useState();

    const validate = (values) => {
        let errors = {};
        let errorMsg = 'This field is required!'

        const emailRegexPattern = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i);

        if(values.feedbackSelect.trim() === '') {
            errors.feedbackSelect = errorMsg;
        }
        if(values.email.trim() === '') {
            errors.email = errorMsg;
        } else if(!emailRegexPattern.test(values.email)) {
            errors.email = 'This is not a valid email format!';
        }
        if(values.message.trim() === '') {
            errors.message = errorMsg;
        }

        return errors;
    };

    const sendFeedback = () => {

        console.log(values);

        fetch('https://contact.googolweb.com/feedback/', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            "feedbackSelect": values.feedbackSelect,
            "email": values.email,
            "message": values.message,
            // "token": token
        })
        })
        .then(res => res.json())
        .then(res => {
            // setLoading(false);
            setResponse(res);
            console.log(res);
        });
    };
    
    const { values, errors, onChange, onSubmit }  = useForm(
        sendFeedback, 
        { feedbackSelect: '', email: '', message: '' }, 
        validate 
    );

    return (
        <Layout>
            <section className='w-100 contact-us-bg' style={{marginTop: '74px'}}>
                <div className='container'>
                    <div className='row align-items-center' style={rowPadding}>
                        <div className='col-md-6 text-white'>
                        <h1 className='display-4'>Feedback</h1>
                        <p className='fs-5'>We welcome your ideas, requests or comments about GoogolWeb. We’ll use them to improve our service.</p>
                        </div>
                        <div className='col-md-6'>
                        
                        </div>
                    </div>
                </div>
            </section>

            <section className='w-100 bg-white'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-8 mx-auto text-dark'> 

                            <GoogleReCaptchaProvider
                                reCaptchaKey="6Lfsi7khAAAAAF2vaMtTTYYm5EWoVkQZwSlDwl57"
                                container={{ // optional to render inside custom element
                                    element: "inline-badge",
                                    parameters: {
                                    badge: 'inline', // optional, default undefined
                                    }
                                }}>
                                {/* <YourReCaptchaComponent/> */}
                                {/* <GoogleReCaptcha onVerify={(token) => {
                                    console.log(token);
                                }}/> */}
                                
                                <div className="col">
                                    <form onSubmit={onSubmit} className='row gy-3 py-5'>
                                        <h1 className='w-75 mx-auto text-center text-capitalize'>We love to hear from you</h1>
                                        <p className='w-75 mx-auto text-center mb-5'>
                                            To help us improve, we love to hear your suggestions.
                                        </p>

                                        <div className="col-12 col-md-6">
                                            <label htmlFor="projectContent" className='form-label'>What would you tell us about:</label>
                                            <select 
                                                className={`form-select ${errors.feedbackSelect && 'is-invalid'}`} 
                                                id='feedbackSelect' 
                                                name='feedbackSelect' 
                                                aria-label="" 
                                                defaultValue={values.feedbackSelect ? values.feedbackSelect : ""} 
                                                onChange={onChange} >
                                                <option value="">- Select -</option>
                                                <option value="Something that I like">Something that I like</option>
                                                <option value="Something that I do not like">Something that I do not like</option>
                                                <option value="Suggest an improvement">Suggest an improvement</option>
                                                <option value="Suggest a new idea">Suggest a new idea</option>
                                                <option value="General comments">General comments</option>
                                            </select>
                                            
                                            {errors.feedbackSelect && (
                                                <span className='invalid-feedback'>
                                                    {errors.feedbackSelect}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-12 col-md-6">
                                            <label htmlFor="email" className='form-label'>Email:</label>
                                            <input
                                                type="text"
                                                id="email"
                                                className={`form-control ${errors.email && 'is-invalid'}`} 
                                                name="email"
                                                value={values.email}
                                                onChange={onChange}
                                            />
                                            {errors.email && (
                                                <span className='invalid-feedback'>
                                                    {errors.email}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="company" className='form-label'>Mesage:</label>
                                            <textarea 
                                            id="message" 
                                            name='message' 
                                            rows="5" 
                                            className={`form-control ${errors.message && 'is-invalid'}`} 
                                            onChange={onChange} 
                                            value={values.message}>
                                            </textarea>
                                            {errors.message && (
                                                <span className='invalid-feedback'>
                                                    {errors.message}
                                                </span>
                                            )}
                                        </div>

                                        <div id="inline-badge"></div>

                                        <div className='col-12'>
                                            <button type="submit" className="btn btn-orange default-padding shadow text-capitalize" style={{ width: '150px' }}> Send </button>
                                    </div>
                                        {response && (
                                            <div className='success-message'>
                                                {response}
                                            </div>
                                        )}
                                    </form>
                                </div>
                                {/* </GoogleReCaptcha> */}
                            </GoogleReCaptchaProvider>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export const Head = () => (
    <SEO title="Feedback" />
)